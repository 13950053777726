import React, { Component } from 'react';
import { Form, Row, Col } from 'reactstrap';
import { request, request_file } from './request.js';
import Swal from 'sweetalert2'
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import 'jquery/dist/jquery.min.js';
import './App.css';
import './fonts/css/open-iconic.css';
import 'animate.css';
import imagen from './imagenes/hombre.png';

class App extends Component 
{
	constructor(props)
	{
		super(props)
		this.state = {
			curp : '',
			registro :
			{
				grado : '',
				grupo : '',
				apellido1 : '',
				apellido2 : '',
				nombre : '',
				curp : '',
				color : ''
			},
			foto : '',
			conectado : false
		}
		this.registrar_movimiento = this.registrar_movimiento.bind(this);
	}

	componentDidMount()
	{
		var { conectado, foto } = this.state;
		let temp = this;

		request.get(`/ProbarConexion`)
		.then(function(response)
		{
			if(response.status === 200)
			{
				if(response.data)
				{
					conectado = true;
		 			temp.setState({ conectado : conectado });
		 		}
		 	}
		})
		.catch(error => {} );

		foto=imagen;

		this.setState({ conectado : conectado, foto : foto });
	}

	submit=(evt)=>{
		evt.preventDefault();
	}

	handleInputChange=(event)=>
	{
		var { registro, curp } = this.state;
		const value = event.target.value;		
		
		curp = value;
		this.setState({ curp : curp });
	}

	registrar_movimiento=(evt)=>
	{
		evt.preventDefault();
		var { curp, registro, foto } = this.state;
		let temp = this;

		request.get(`/RegistrarMovimiento/${curp}`)
		.then(function(response)
		{
			if(response.status === 200)
			{
				if(response.data)
				{
		 			registro = response.data;
		 			if(registro['color']===1)
		 				registro['color']="NARANJA";
		 			else
		 				registro['color']="VERDE";
		 			curp="";
					temp.setState({ curp : curp, registro : registro });
		 		}
		 		else
		 		{
		 			curp="";
		 			registro['grado'] = '';
					registro['grupo'] = '';
					registro['apellido1'] = '';
					registro['apellido2'] = '';
					registro['nombre'] = '';
					registro['curp'] = '';
					registro['color'] = '';
					foto=imagen;

	 				Swal.fire({
						position: 'top-end',
						icon: 'error',
						title: 'No Registrado',
						toast: true,
						showConfirmButton: false,
						timer: 1500
					});
					temp.setState({ curp : curp, registro : registro, foto : foto });
		 			
		 		}
				
		 		
		 	}
		})
		.catch(error => {} );

		request_file().post(`/MostrarImagen?curp=${curp}`)
		.then(response =>
		{
			const file = new Blob([response.data], {type: 'image/jpeg' });
			const fileURL = URL.createObjectURL(file);
			foto=fileURL;
			temp.setState({ foto : foto });
		})
		.catch(error => { });
		curp = '';
		this.setState({ curp : curp, registro : registro });
	}

	render()
	{
		let { conectado, curp, registro, foto } = this.state;

		return (
			<div className="body">
				<div className="main">
					<Row className="pl-1 pr-1">
						<Col className="col-12">
							<Form onSubmit={this.registrar_movimiento}>
								<Row>
									<Col>
										<div className="cen pl-1 pr-1 mb-1 loginForm">
											<span className="b">Esc. Sec. #5 Moises Saenz Garza</span>
										</div>
										<div className="pl-1 pr-1 input-group mb-1 loginForm">
											<span className="input-group-text" id="basic-addon1"> Registro de entrada </span>
											<input type="password" autoFocus autoComplete="off" className="form-control" placeholder="" name="curp" value={curp} aria-label="curp" aria-describedby="basic-addon1" onChange={this.handleInputChange}/>
											
										</div>
									</Col>
								</Row>
							</Form>
		{/* CUADRO DE FOTO */}					
							<Row>
								<Col>
									<div className="pl-2 pr-2 input-group mb-1 loginForm cuad">
										<table>
											<tr className="f ml-2">
												<td className="f">
													<img align="middle" src={foto} class="img-thumbnail" alt="..."/>
												</td>
											</tr>
											{
												conectado ?
													<tr>
														<td className="datos">
															
															<span className="ne b xl"> {registro.apellido1} </span>
															<br/>
															<span className="ne b lg"> {registro.nombre} </span>
															<br/>
															<span className="ne b xxl"> {registro.grado} {registro.grupo} </span>
															<br/>
															<span className="ne b xxl"> {registro.color} </span>
															<br/><br/>
														</td>
													</tr>
												:
													<span className="oi ro" data-glyph="signal" aria-hidden="true"> No hay conexión a internet </span>
											}
										</table>
									</div>
								</Col>
							</Row>
						</Col>
					</Row>
				</div>
			</div>
		);
	}
}

export default App;